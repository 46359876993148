// src/App.js
import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { RouterProvider } from 'react-router-dom';
import router from './routes/routes';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './layout/authContext';
const App = () => (
    <Fragment>
        <AuthProvider>
            <ToastContainer />
            <RouterProvider router={router} />
        </AuthProvider>
    </Fragment>
);

export default App;
