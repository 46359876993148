import React, { useEffect, useState } from 'react'
import ElectricityCart from '../component/charts/electricityCart'
import { useQuery } from '@apollo/client';
import { ACCOUNT_QUERY, BUILDING_QUERY, UTILITIES_QUERY } from '../graphql/mutations';
import useApolloClient from '../apolloClient1';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import Moment from 'react-moment';
import { Spinner } from 'react-bootstrap';

const ElectricityDetails = () => {

    const { id } = useParams();
    const [token, setToken]=useState(false);
    const [timeStart, setTimeStart]=useState();
    const [timeEnd, setTimeEnd]=useState();
    const [initMonth, setInitMonth]=useState('currentMonth');
    const [totalElectricityRead, setTotalElectricityRead] = useState(0);

    useEffect(()=>{
        // const accountId = localStorage.getItem("accountId");
        let token = localStorage.getItem('token')
        setToken(token)
        let timeStart = moment().startOf('month');
        let timeEnd = moment();
        setTimeStart(timeStart);
        setTimeEnd(timeEnd);
    },[])

    const utilitiesClient = useApolloClient('https://api.thecarbonshield.com/utilities/graphql', token);

    const { data: utilitiesData, loading } = useQuery(UTILITIES_QUERY, {
        client: utilitiesClient,
        variables: {buildingUuid:id, utilityType: 'ELECTRICITY', timeStart, timeEnd}
    });
    const handleTimeRangeChange = (data) => {
        console.log('value changed', data)
        setInitMonth(data);
        if(data=='currentMonth') {
            setTimeStart(moment().startOf('month'));
        } else if(data=='last3Months') {
            setTimeStart(moment().subtract(2, 'months').startOf('month'));
        } else if(data=='last6Months') {
            setTimeStart(moment().subtract(5, 'months').startOf('month'));
        } else if(data=='lastYear') {
            setTimeStart(moment().subtract(1, 'year').startOf('month'));
        }
    };

    useEffect(()=>{
        console.log('utilities', utilitiesData)
        setTotalElectricityRead(utilitiesData?.buildingUtilityConsumption);
    }, [utilitiesData])


    return (
        <div style={{ height: '100vh' }}>
            { loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                <Spinner animation="border" role="status"> 
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                </div>
                ) :
            (<div className='electryWRapr'>
                <div className='dashprofileWrp'>
                    <div className='container-fluid'>
                        <div className='build_back mb-0'>
                            <Link to={`/building-details/${id}`}>

                                <svg className='mx-2' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_3510_24756)">
                                        <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z" fill="#253338" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3510_24756">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='electGrapWRapper'>
                    <ElectricityCart onTimeRangeChange={handleTimeRangeChange} initMonth={initMonth} totalRead={totalElectricityRead?.total?.reading} electricityData={totalElectricityRead} unit={'kWh'}  type={'Electricity'} />
                    <div style={{marginTop:'70px', textAlign:'center', width:'70%', marginLeft:'15%'}} className='table_wrapper'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Reading</th>
                                </tr>
                            </thead>
                            <tbody>
                                {totalElectricityRead?.groupedReadings?.map((building)=>(
                                    <tr>
                                        <td>
                                            {/* <b>Forte Condominium</b> <br /> */}
                                            <Moment date={building.groupName} format="Do MMM" />
                                        </td>
                                        <td>                     
                                            {building.reading?.toLocaleString(2)} kWh
                                        </td>
                                    </tr>
                                ))} 
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>) }
        </div>
    )
}

export default ElectricityDetails
