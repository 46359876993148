import React from 'react'

const ProgressBarMultiscore = ({ value, labels, grade }) => {
    return (
        <div>
            <div className='erylevlWRp d-flex gap-2'>
                <span className='enlyD'>{grade}</span> |
                <span className='enlyCnt'>{value}</span>
            </div>
            <div className='rngMuliColrWrp d-flex flex-wrap'>
                {value && (<div className='valueIxon'>
                    <svg style={{marginLeft:`${Number(value || 0) - 2}%`}} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.147877 1.2098L5.12448 7.61556C5.41716 7.99225 6.04652 8.11264 6.53006 7.88463C6.67128 7.81806 6.78999 7.72558 6.87544 7.61556L11.852 1.2098C12.1447 0.833107 11.9897 0.34281 11.5061 0.1152C11.3465 0.0398612 11.1633 0 10.9766 0L1.02336 0C0.457954 0 9.53674e-07 0.35716 9.53674e-07 0.797232C9.53674e-07 0.942727 0.0511694 1.08543 0.147877 1.2098Z" fill="#191926" />
                    </svg>

                </div>)}
                {labels?.map((d, k) => (
                    <div className='rngItem d-flex flex-wrap' key={k} style={{ width: d?.width || 100 }}>
                        <div className='rgnitemBG' style={{ background: d?.color || '#ddd' }}>{d?.name}</div>
                        <div className={`rgnitemMark d-flex ${k==0 ? 'justify-content-between':'justify-content-end'}`}>
                            {k == 0 && (<span>0</span>)}
                            <span>{d?.partition}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ProgressBarMultiscore
