// src/App.js
import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import ErrorPage from './error';
import UnAuthLayout from '../layout/unAuthLayout';
import LoginPage from './login';
import AuthLayout from '../layout/authLayout';
import DashboardPage from './dashboard';
import BuildingDetails from './building-details';
import HomePage from './home';
import Dashboard2Page from './dashboard2';
import BuildingDetails2 from './building-details2';
import Typography from './typography';
import ElectricityDetails from './electricity-details';
import GasDetails from './gas-details';
import WaterDetails from './water-details';
import Oil1Details from './oil1-details';
import Oil2Details from './oil2-details';
import SteamDetails from './steam-details';

const ProtectedRoute = ({ element }) => {    
  let token = localStorage.getItem('token');  
  return token ? element : <Navigate to="/sign-in" />;
};

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    children: [
      {
        element: <UnAuthLayout />, // Layout for unauthenticated users
        children: [
          { path: "/", element: <LoginPage /> },
        ],
      },
      {
        element: <AuthLayout />, // Layout for authenticated users
        children: [
          { path: "/home", element: <ProtectedRoute element={<HomePage />} /> },
          { path: "/dashboard", element: <ProtectedRoute element={<DashboardPage />} /> },
          { path: "/dashboard2", element: <ProtectedRoute element={<Dashboard2Page />} /> },
          { path: "/building-details/:id", element: <ProtectedRoute element={<BuildingDetails />} /> },
          { path: "/building-details2", element: <ProtectedRoute element={<BuildingDetails2 />} /> },
          { path: "/typography", element: <ProtectedRoute element={<Typography />} /> },
          { path: "/electricity-details/:id", element: <ProtectedRoute element={<ElectricityDetails />} /> },
          { path: "/gas-details/:id", element: <ProtectedRoute element={<GasDetails />} /> },
          { path: "/water-details/:id", element: <ProtectedRoute element={<WaterDetails />} /> },
          { path: "/oil1-details/:id", element: <ProtectedRoute element={<Oil1Details />} /> },
          { path: "/oil2-details/:id", element: <ProtectedRoute element={<Oil2Details />} /> },
          { path: "/steam-details/:id", element: <ProtectedRoute element={<SteamDetails />} /> },
          // Additional authenticated routes can go here
        ],
      },
    ],
  },
]);

export default router;
