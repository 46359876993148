import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import ProgressBarMultiscore from '../progress-bar/progress-bar-multiscore';
import ProgressBar from '../progress-bar/progress-bar';

const Summery = () => {
    return (
        <div className='tabBodyWrp'>
            <div className='tabHeading'>
                <h3>Summary</h3>
            </div>
            <div className='enery_carbon_wrp d-flex'>
                <div className='enery_wrp'>
                    <h4>Energy Star score</h4>
                    <ProgressBarMultiscore value={53} labels={[
                        { name: 'D', partition: 55, color: '#ED4833', width: '55%' },
                        { name: 'C', partition: 70, color: '#F6B135', width: '15%' },
                        { name: 'B', partition: 85, color: '#F5D738', width: '15%' },
                        { name: 'A', partition: 100, color: '#39B085', width: '15%' }
                    ]} />
                </div>
                <div className='carbon_wrp'>
                    <h4>Energy Star score</h4>
                    <ProgressBar percent="32%" value={'106,446'} total="354,823" unit='kgCo2e' />

                </div>
                <div className='ussessyearp'>
                    <h4>Usages this year</h4>
                    <div className='listuseps d-flex flex-wrap'>
                        <div className='listUgrop'>
                            <h6>Electricity</h6>
                            <h3>123,456 kWh</h3>
                        </div>
                        <div className='listUgrop'>
                            <h6>Gas</h6>
                            <h3>123,456 CCF</h3>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Summery
