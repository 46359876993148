import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token,
      email
    }
  }
`;

export const ACCOUNT_BY_EMAIL = gql`
    query ($email: String!) {
        getAccountByEmail(email: $email) {
            uuid,
            firstName,
        }
    }
`;

export const ACCOUNT_QUERY = gql`
  query Account($id: String!) {
  buildingsForAccount(accountUuid: $id) {
    uuid,
    name,
    buildingType {
        name
    },
    area,
    numUnits,
    utilitySources {
        electricity {
            id,
            provider {
                type
            },
            credentials
        },
        water {
            id,
            provider {
                type
            },
            credentials
        },
        gas {
            id,
            provider {
                type
            },
            credentials
        }
    },
    submeters {
        uuid,
        name,
        sources {
            electricity {
                provider {
                    type
                }
            },
            water {
                provider {
                    type
                }
            }
        }
    },
    carbonEmissions {
        emissionsUsed,
        emissionsLimit,
        current {
            year,
            fine {
                amount
            }
        }
        historical {
            year,
            fine {
                amount
            }
        }
    },
    currentEnergyStarGrade {
        grade
    },
    address {
        street1,
        street2,
        city,
        state,
        zipCode
    },
    imageUrl
  }
}
`;

export const BUILDING_QUERY = gql`
query(
    $buildingUuid: String!
) {
  building(
    uuid: $buildingUuid
  ) {
    uuid
    name
    numUnits
    area
    buildingType {
        name
        uuid
    }
    buildingSections {
        area
        type {
            name
            uuid
        }
    }
    imageUrl
    currentEnergyStarGrade {
      grade
      createdTime
    }
    carbonEmissions {
        emissionsUsed,
        emissionsLimit,
        current {
            year,
            fine {
                amount
            }
        }
        historical {
            year,
            fine {
                amount
            }
        }
    }
    energyStarGrades {
      grade
      createdTime
    }
    address {
      street1
      street2
      state
      city
      zipCode
    }
    utilitySources {
      electricity {
        id
        meters { uuid apiId name }
        provider {
          type
          name
          configuration { offsetDays aggregateFunction }
        }
        credentials
      }
      water {
        id
        meters { uuid apiId name }
        provider {
          type
          name
          configuration { offsetDays aggregateFunction }
        }
        credentials
      }
      gas {
        id
        meters { uuid apiId name }
        provider {
          type
          name
          configuration { offsetDays aggregateFunction }
        }
        credentials
      }
    }
    submeters {
      uuid
      name
      sources {
        electricity {
          id
          meters { uuid apiId name }
          provider {
            type
            configuration { offsetDays aggregateFunction }
          }
          credentials
        }
        water {
          id
          meters { uuid apiId name }
          provider {
            type
            configuration { offsetDays aggregateFunction }
          }
          credentials
        }
        gas {
          id
          meters { uuid apiId name }
          provider {
            type
            configuration { offsetDays aggregateFunction }
          }
          credentials
        }
      }
    }
  }
}
`

export const UTILITIES_QUERY = gql`
query Utilities($utilityType: UtilityType!, $buildingUuid: String!, $submeterUuid: String, $timeStart: String!, $timeEnd: String!, $groupingType: GroupingType) {
  buildingUtilityConsumption(
    utilityType: $utilityType,
    buildingUuid: $buildingUuid,
    submeterUuid: $submeterUuid,
    timeStart: $timeStart,
    timeEnd: $timeEnd,
    groupingType: $groupingType
  ) {
    buildingUuid
    total {
      reading
    }
    groupedReadings {
      reading
      groupName
    }
  }
}
`