// let ROOT_URL ='http://staging.alphonic.net.in:6002/api/v1/c'
let ROOT_URL = "";
let IMAGE_URL = "";
console.log(process.env.REACT_APP_SERVER);
if (process.env.REACT_APP_SERVER?.trim() == "production") {
  ROOT_URL = "http://localhost:9000/api/v1";
} else {
  ROOT_URL = "http://localhost:9000/api/v1";
}

ROOT_URL = "http://192.168.31.9:9000/api/v1";
export { IMAGE_URL };
export default ROOT_URL;
