import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './header';
import Footer from './footer';

const AuthLayout = () => (
    <div className='wrapperUnAuth'>
        <Header />
        <div className='authBodyWRp'>
            <Outlet />
        </div>
        <Footer />
    </div>
);

export default AuthLayout;