// src/useApolloClient.js
import { useMemo } from 'react';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const useApolloClient = (uri, token) => {
    // console.log('toke',token, uri)
  return useMemo(() => {
    return new ApolloClient({
      link: new HttpLink({ 
        uri,
        headers: {
            Authorization: `Bearer ${token}`,
        },
      }),
      cache: new InMemoryCache(),
    });
  }, [uri, token]);
};

export default useApolloClient;
