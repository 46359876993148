import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { Button } from 'react-bootstrap';
import Overview from '../component/building/overview';
import Configuration from '../component/building/configuration';
import Summery from '../component/building2/summery';

const BuildingDetails2 = () => {
    return (
        <>
            <div className='build2WRapper buildingDetalWrp d-flex flex-wrap'>
                <div className='buildLefSide'>
                    <div className='build_back d-flex justify-content-between align-items-center'>
                        <a>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_3510_24756)">
                                    <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z" fill="#253338" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_3510_24756">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <Dropdown as={ButtonGroup}>
                            <Button className='btnAddML btn-md'>Add</Button>
                            <Dropdown.Toggle split variant="primary" className='btn-md' />
                            <Dropdown.Menu className="super-colors">
                                <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                                <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                                <Dropdown.Item eventKey="3" active>
                                    Active Item
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='buidcontwrp'>
                        <h5 className='d-flex gap-4'>
                            <span>Building details</span>
                        </h5>
                        <h2>Forte Condominium</h2>
                        <p>230 Ashland Pl, Brooklyn, NY 11217</p>
                    </div>
                </div>
                <div className='buildRideside'>
                    <div className="btnBuildDetls prfbtngrp d-flex justify-content-end">
                        
                    </div>
                    <Tabs
                        defaultActiveKey="Summery"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="Summery" title="Summery">
                            <Summery />
                        </Tab>
                        <Tab eventKey="Electricity" title="Electricity">
                            
                        </Tab>
                        <Tab eventKey="Gas" title="Gas">
                        </Tab>
                        <Tab eventKey="Carbon" title="Carbon emissions"></Tab>
                        <Tab eventKey="Water" title="Water"></Tab>
                        <Tab eventKey="Meters" title="Meters"></Tab>
                        <Tab eventKey="Users" title="Users"></Tab>
                    </Tabs>
                </div>
            </div>
        </>
    )
}

export default BuildingDetails2
