// src/context/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userInfo, setUserInfo] = useState({});

  // Toggle authentication status (for demonstration purposes)
  const loginn = () => setIsAuthenticated(true);
  const logout = () => {
    setIsAuthenticated(false)
    setUserInfo({})
  };


  useEffect(()=>{
    let token = localStorage.getItem('token')
    if(token){
        loginn()
    }
  },[])

  return (
    <AuthContext.Provider value={{ isAuthenticated, loginn, logout, userInfo, setUserInfo }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
