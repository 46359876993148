import { useFormik } from 'formik';
import * as Yup from "yup";
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import common from '../services/common';
import ROOT_URL from '../services/api-url';
import { ErrorMessage } from '../component/formik/errorMessage';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../layout/authContext';
import { useMutation, useQuery } from '@apollo/client';
import { ACCOUNT_BY_EMAIL, LOGIN_MUTATION } from '../graphql/mutations';
import useApolloClient from '../apolloClient1';
import { Spinner } from 'react-bootstrap';

const LoginPage = () => {
    const [email, setEmail]=useState(false);
    const [token, setToken]=useState({token:''});
    // const [loading, setLoading]=useState({token:false});

    const client = useApolloClient('https://api.thecarbonshield.com/accounts/graphql', token);

    const { loginn } = useAuth();
    const navigate = useNavigate()
    const initialValues = {
        email: "",
        password: "",
    };
    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Please enter valid email')
            .required('Please enter email'),
        password: Yup.string().required("Please enter password"),
    });

    const [login, { data, loading, error }] = useMutation(LOGIN_MUTATION, {
        client,
        refetchQueries: [
            { query: ACCOUNT_BY_EMAIL, variables: { email: email } }, // This will refetch the 'posts' query
        ],
    });
    // const [Account, { qdata, qloading, qerror }] = useQuery(ACCOUNT_BY_EMAIL, {client});

   // Formik setup
   const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            submitForm(values);
        },
    });


  // Function to handle form submission
  const submitForm = async (userData) => {
    try {
        // Make GraphQL login request
        setEmail(userData.email);
        const response = await login({
            variables: {
                email: userData.email,
                password: userData.password,
            },
        });

        // If login is successful and token is returned
        if (response.data && response.data.login.token) {
            // Store token and user data in localStorage
            setToken(response.data.login.token);
            localStorage.setItem("token", response.data.login.token);
            localStorage.setItem("email", response.data.login.email);
            localStorage.setItem("name", response.data?.login?.user?.name);

            // const { data, loading, error } = useQuery(ACCOUNT_BY_EMAIL);
            // console.log('dddd',data)
            // Update authentication state in context
            loginn(response.data.login.user);

            // Show success message
            toast.success("You have successfully logged in!");

            // Redirect to dashboard
            navigate('/dashboard');
        } else {
            toast.error("Login failed. Please check your credentials.");
        }
    } catch (err) {
        // Handle errors
        toast.error("An error occurred during login. Please try again.");
        console.error("Login error:", err);
    }
};

    return (
        <div className='loginWrp'>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                <Spinner animation="border" role="status"> 
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                </div>
            ): (
                <form onSubmit={formik.handleSubmit}>
                    <div className='login_column_wrp full_height d-flex flex-column justify-content-between'>
                        <div className='login_midd'>
                            <div className='lg_logo'>
                                <img src='/assets/images/logo.png' className='img-fluid' alt='Logo' />
                                <h3 className='common_heading'>Sign In</h3>
                            </div>
                            <div className='lg_form'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='form_group form_input_email mb-3'>
                                            <input type='email' {...formik.getFieldProps("email")} className='form-control btn-radius-50' placeholder='Email' />
                                            <ErrorMessage formik={formik} name="email" />
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className='form_group form_input_email mb-3'>
                                            <input type='password' {...formik.getFieldProps("password")} className='form-control btn-radius-50' placeholder='Password' />
                                            <ErrorMessage formik={formik} name="password" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='login_bottom'>
                            <button type='submit' className='btn-md btn-theme btn-radius-50 full_width'>Sign In</button>
                            
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default LoginPage;