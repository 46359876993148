import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ACCOUNT_BY_EMAIL, ACCOUNT_QUERY } from '../graphql/mutations';
import { useQuery } from '@apollo/client';
import useApolloClient from '../apolloClient1';
import { Spinner } from 'react-bootstrap';

const DashboardPage = () => {
    const [token, setToken]=useState(false);
    const [email, setEmail]=useState(false);
    const [accountId, setAccountId]=useState(false);
    const [firstName, setFirstName]=useState(false);
    const [buildings, setBuildings]=useState([]);
    const [totalSubmeters, setTotalSubmeters]=useState(0);

    useEffect(()=>{
        let token = localStorage.getItem('token')
        let email = localStorage.getItem('email')
        setToken(token)
        setEmail(email)
    },[])

    const client = useApolloClient('https://api.thecarbonshield.com/accounts/graphql', token);
    const buildingClient = useApolloClient('https://api.thecarbonshield.com/buildings/graphql', token);

    const { data, loading, error } = useQuery(ACCOUNT_BY_EMAIL, {
        client,
        variables: {email:email}
    });

    useEffect(()=>{
        console.log('data',data, data?.getAccountByEmail?.uuid)
        localStorage.setItem('accountId',data?.getAccountByEmail?.uuid)
        setAccountId(data?.getAccountByEmail?.uuid)
        setFirstName(data?.getAccountByEmail?.firstName)
    },[data])

    const { data: accountData, loading: buildingLoad } = useQuery(ACCOUNT_QUERY, {
        client: buildingClient,
        skip: !data,
        variables: {id:accountId}
    });
    useEffect(()=>{
        setBuildings(accountData?.buildingsForAccount)
        let count = 0;
        accountData?.buildingsForAccount?.map(building=>{
            count += building?.submeters?.length
        })
        setTotalSubmeters(count)
        console.log('ssss',buildings, count)

    },[accountData])
    console.log('get data',accountData,buildings)
    const getGrade= (grade)=>{
        if(grade>85) {
            // setGrade('A')
            return 'A'
        } else if(grade>70) {
            // setGrade('B')
            return 'B'

        } else if(grade>55) {
            // setGrade('C')
            return 'C'

        } else{
            // setGrade('D')
            return 'D'

        }
    };

    return (
        <div className='dashboardWRp' style={{ height: '100vh' }}>
            {buildingLoad || loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                <Spinner animation="border" role="status"> 
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                </div>
                ) : (
                    <div className='container-fluid'>
                        <div className='dashprofileWrp'>
                            <div className='row align-items-center'>
                                <div className='col-8'>
                                    <div className='profInoImgWrp gap-3 d-flex align-items-center'>
                                        {/* <div className='prfImg'>
                                            <img src='/assets/images/profile.png' className='img-fluid' />
                                        </div> */}
                                        <div className='profCotn'>
                                            <h3>Hi, {firstName}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className="prfbtngrp d-flex justify-content-end">
                                        {/* <Dropdown as={ButtonGroup}>
                                            <Button className='btnAddML btn-md'>Add</Button>
                                            <Dropdown.Toggle split variant="primary" className='btn-md' />
                                            <Dropdown.Menu className="super-colors">
                                                <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                                                <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                                                <Dropdown.Item eventKey="3" active>
                                                    Active Item
                                                </Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='tablCountWrp d-flex gap-3'>
                            <div className='countrwp'>
                                <h5>Total buildings</h5>
                                <h2>{buildings?.length || 0}</h2>
                            </div>
                            {/* <div className='countrwp'>
                                <h5>Total meters</h5>
                                <h2>{totalSubmeters}</h2>
                            </div> */}
                            {/* <div className='countrwp'>
                                <h5>Total buildings</h5>
                                <h2>14</h2>
                            </div> */}
                        </div>
                        <div className='table_wrapper'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>ft²</th>
                                        <th>Building type</th>
                                        <th>Carbon emissions</th>
                                        <th>Energy Star score</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {buildings?.map((building)=>(
                                        <tr>
                                            <td>
                                                {/* <b>Forte Condominium</b> <br /> */}
                                                <Link to={`/building-details/${building.uuid}`}>
                                                    {building.name}
                                                </Link>
                                            </td>
                                            <td>{building.area?.toLocaleString()} ft²</td>
                                            <td>Mixed use</td>
                                            <td>{building.carbonEmissions.emissionsUsed?.toLocaleString()} kgC02e</td>
                                            <td>{getGrade(building.currentEnergyStarGrade.grade)} | {building.currentEnergyStarGrade.grade?.toFixed(2)}</td>
                                            <td>
                                                <div className='tbleAction'>
                                                    <Link to={`/building-details/${building.uuid}`}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.98296 6.98349L14.811 11.8116C14.861 11.8615 14.8891 11.9293 14.8891 12C14.8891 12.0707 14.861 12.1385 14.811 12.1884L9.98291 17.0165" stroke="#5C5C5C" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>

                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
            ) }
        </div>
    )
};

export default DashboardPage;