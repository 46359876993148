import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { Button, Spinner } from 'react-bootstrap';
import Overview from '../component/building/overview';
import Configuration from '../component/building/configuration';
import { useQuery } from '@apollo/client';
import { ACCOUNT_QUERY, BUILDING_QUERY, UTILITIES_QUERY } from '../graphql/mutations';
import useApolloClient from '../apolloClient1';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const BuildingDetails = () => {

    const [token, setToken]=useState(false);
    const [buildingData, setBuildingData]=useState(false);
    const [timeStart, setTimeStart]=useState();
    const [timeEnd, setTimeEnd]=useState();
    const [monthtimeStart, setMonthTimeStart]=useState();
    const [monthtimeEnd, setMonthTimeEnd]=useState();
    const [totalElectricityRead, setTotalElectricityRead] = useState(0);
    const [totalGasRead, setTotalGasRead] = useState(0);
    const [totalWaterRead, setTotalWaterRead] = useState(0);
    const [totalOil1Read, setTotalOil1Read] = useState(0);
    const [totalOil2Read, setTotalOil2Read] = useState(0);
    const [totalSteamRead, setTotalSteamRead] = useState(0);
    const { id } = useParams();

    useEffect(()=>{
        const accountId = localStorage.getItem("accountId");
        let token = localStorage.getItem('token')
        setToken(token)
        let timeStart = moment().startOf('year');
        let timeEnd = moment();
        let timeStart1 = moment().startOf('year');
        let timeEnd1 = moment();
        setTimeStart(timeStart);
        setTimeEnd(timeEnd);
        setMonthTimeStart(timeStart1);
        setMonthTimeEnd(timeEnd1);
    },[])


    const client = useApolloClient('https://api.thecarbonshield.com/buildings/graphql', token);
    const utilitiesClient = useApolloClient('https://api.thecarbonshield.com/utilities/graphql', token);

    const { data, loading, error } = useQuery(BUILDING_QUERY, {
        client,
        variables: {buildingUuid:id}
    });
    useEffect(()=>{
        let buildingData = {...data?.building}
        if(id=='7c0ee1be-8b7a-4fef-a641-e338992d6678' && data?.building) {
            buildingData.showElectricity = true;
            buildingData.showGas = true;
            buildingData.showOil1= false;
            buildingData.showOil2 = false;
            buildingData.showSteam = true;
            buildingData.showWater = false;
        } else if(id=='9cb894b1-8e3b-4b51-a799-564a426eda10' && data?.building) {
            buildingData.showElectricity = true;
            buildingData.showGas = true;
            buildingData.showOil1= false;
            buildingData.showOil2 = true;
            buildingData.showSteam = false;
            buildingData.showWater = false;
        } else if(data?.building) {
            buildingData.showElectricity = true;
            buildingData.showGas = true;
            buildingData.showOil1= true;
            buildingData.showOil2 = true;
            buildingData.showSteam = true;
            buildingData.showWater = true;
        }
        setBuildingData(buildingData)
    },[data])

    const { data: utilitiesData, loading: buildingLoad } = useQuery(UTILITIES_QUERY, {
        client: utilitiesClient,
        skip: !buildingData,
        variables: {buildingUuid:id, utilityType: 'ELECTRICITY', timeStart, timeEnd}
    });

    useEffect(()=>{
        console.log('utilities', utilitiesData)
        setTotalElectricityRead(utilitiesData?.buildingUtilityConsumption);
    }, [utilitiesData])

    const { data: gasData } = useQuery(UTILITIES_QUERY, {
        client: utilitiesClient,
        skip: !buildingData,
        variables: {buildingUuid:id, utilityType: 'GAS', timeStart:monthtimeStart, timeEnd:monthtimeEnd}
    });

    useEffect(()=>{
        console.log('utilities3', gasData)
        setTotalGasRead(gasData?.buildingUtilityConsumption);
    }, [gasData])
    
    const { data: waterData } = useQuery(UTILITIES_QUERY, {
        client: utilitiesClient,
        skip: !buildingData,
        variables: {buildingUuid:id, utilityType: 'WATER', timeStart:monthtimeStart, timeEnd:monthtimeEnd}
    });
    
    useEffect(()=>{
        console.log('utilities2', waterData)
        setTotalWaterRead(waterData?.buildingUtilityConsumption);
    }, [waterData])

    const { data: oil1Data } = useQuery(UTILITIES_QUERY, {
        client: utilitiesClient,
        skip: !buildingData,
        variables: {buildingUuid:id, utilityType: 'OIL1', timeStart:monthtimeStart, timeEnd:monthtimeEnd}
    });
    
    useEffect(()=>{
        // console.log('utilities2', waterData)
        setTotalOil1Read(oil1Data?.buildingUtilityConsumption);
    }, [oil1Data])

    const { data: oil2Data } = useQuery(UTILITIES_QUERY, {
        client: utilitiesClient,
        skip: !buildingData,
        variables: {buildingUuid:id, utilityType: 'OIL2', timeStart:monthtimeStart, timeEnd:monthtimeEnd}
    });

    useEffect(()=>{
        // console.log('utilities2', waterData)
        setTotalOil2Read(oil2Data?.buildingUtilityConsumption);
    }, [oil2Data])

    const { data: steamData } = useQuery(UTILITIES_QUERY, {
        client: utilitiesClient,
        skip: !buildingData,
        variables: {buildingUuid:id, utilityType: 'STEAM', timeStart:monthtimeStart, timeEnd:monthtimeEnd}
    });
    
    useEffect(()=>{
        // console.log('utilities2', waterData)
        setTotalSteamRead(steamData?.buildingUtilityConsumption);
    }, [steamData])

    return (
        <div style={{ height: '100vh' }}>
        {buildingLoad || loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                <Spinner animation="border" role="status"> 
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                </div>
                ) : (
            <div className='buildingDetalWrp d-flex'>
                <div className='buildLefSide'>
                    <div className='build_back'>
                        <Link to={`/dashboard`}>

                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_3510_24756)">
                                    <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z" fill="#253338" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_3510_24756">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </Link>
                    </div>
                    <div className='buidcontwrp'>
                        <h5 className='d-flex gap-4'>
                            <h2>Building details</h2>
                            {/* <Link to="">Edit</Link> */}
                        </h5>
                        {/* <h2>Forte Condominium</h2> */}
                        <p>{buildingData?.address?.street1}, {buildingData?.address?.city}, {buildingData?.address?.state} {buildingData?.address?.zipCode}</p>
                    </div>
                    <div className='buildslistWrp'>
                        <div className='buildRo0w d-flex gap-3 mb-3'>
                            <div className='buildkeyleft'>
                                Type
                            </div>
                            <div className='buildkeyright'>
                                {buildingData?.buildingType?.name}
                            </div>
                        </div>
                        <div className='buildRo0w d-flex gap-3 mb-3'>
                            <div className='buildkeyleft'>
                                Size
                            </div>
                            <div className='buildkeyright'>
                                {buildingData?.area?.toLocaleString()} ft²
                            </div>
                        </div>
                        {/* <div className='buildRo0w d-flex gap-3 mb-3'>
                            <div className='buildkeyleft'>
                                Users
                            </div>
                            <div className='buildkeyright'>
                                4
                            </div>
                        </div> */}
                        
                    </div>
                </div>
                <div className='buildRideside'>
                    <div className="btnBuildDetls prfbtngrp d-flex justify-content-end">
                        {/* <Dropdown as={ButtonGroup}>
                            <Button className='btnAddML btn-md'>Add</Button>
                            <Dropdown.Toggle split variant="primary" className='btn-md' />
                            <Dropdown.Menu className="super-colors">
                                <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                                <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                                <Dropdown.Item eventKey="3" active>
                                    Active Item
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                    </div>
                    <Tabs
                        defaultActiveKey="Overview"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="Overview" title="Overview">
                            <Overview building={buildingData} electricityData={totalElectricityRead} gasData={totalGasRead} waterData={totalWaterRead} oil1Data={totalOil1Read} oil2Data={totalOil2Read} steamData={totalSteamRead} buildingId={id} />
                        </Tab>
                        {/* <Tab eventKey="Configuration" title="Configuration">
                            <div className='tabBodyWrp'>
                                <Configuration />
                            </div>
                        </Tab>
                        <Tab eventKey="Users" title="Users">
                            <div className='tabBodyWrp'>

                            </div>
                        </Tab>
                        <Tab eventKey="Bills" title="Bills">
                            <div className='tabBodyWrp'>

                            </div>
                        </Tab> */}
                    </Tabs>
                </div>
            </div>
        )}
        </div>
    )
}

export default BuildingDetails
