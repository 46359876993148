import React from 'react'

const ProgressBar = ({ value, percent, total, unit }) => {
    return (
        <div>
            <div className='cbnLimtiWRp mb-3 d-flex justify-content-between align-items-end gap-2'>
                <div className='cbtlimPerc'>
                    <h3>{percent}%</h3>
                    <p>of limit used</p>
                </div>
                <div className='carnlCnt'>$0 fine so far</div>
            </div>
            
            <div className='crlSlidRWrp'>
                    <div className='csrlFill' style={{width:`${percent}%`}}></div>
                    {/* <Form.Range value="50" /> */}
                </div>

            <div className='ranslidWrp'>{value?.toLocaleString()} / {total?.toLocaleString()} {unit}</div>
        </div>
    )
}

export default ProgressBar
