import React from 'react'

export const ErrorMessage = ({formik, name}) => {
  return (
    <>
        {formik?.touched?.[name] && formik.errors?.[name] ? (
            <div className="errorMsg">{formik.errors?.[name]}</div>
        ) : null}
    </>
  )
}
export const ErrorMessageOBJ = (props) => {
    const {formik, name, parent} = props;
  return (
    <>
        {formik?.touched?.[parent]?.[name] && formik.errors?.[parent]?.[name] ? (
            <div className="errorMsg">{formik.errors?.[parent]?.[name]}</div>
        ) : null}
    </>
  )
}
export const ErrorMessageARRAY = (props) => {
    const {formik, name, parent, index} = props;
  return (
    <>
      {formik?.touched?.[parent] && formik.errors?.[parent] && (
        formik?.touched?.[parent][index]&& formik.errors?.[parent][index] && (
          formik?.touched?.[parent][index]?.[name] && formik.errors?.[parent][index]?.[name] ? (
              <div className="errorMsg">{formik.errors?.[parent][index]?.[name]}</div>
          ) : null
        )
      )}
    </>
  )
}

/* export const ErrorMessageARRAY = (props) => {
  const {formik, name, parent, index} = props;
return (
  <>
    {formik?.touched?.[parent] && formik.errors?.[parent] && (
      formik?.touched?.[parent][index]&& formik.errors?.[parent][index] && (
        formik?.touched?.[parent][index]?.[name] && formik.errors?.[parent][index]?.[name] ? (
            <div className="errorMsg">{formik.errors?.[parent][index]?.[name]}</div>
        ) : null
      )
    )}
  </>
)
} */
