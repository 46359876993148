// src/apolloClient.js
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thecarbonshield.com/accounts/graphql', // replace with your API URL
  }),
  cache: new InMemoryCache(),
});

export default client;
